<template>
  <div class="proposal__wrap">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>操作记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="section shadow filter__wrap">
      <el-radio-group v-model="table.params.type" @change="handleTabs">
        <el-radio-button :label="1">投递记录</el-radio-button>
        <el-radio-button :label="2">企业获取记录</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="table.params.type === 1 ? field : field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </VTable>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'SalesmanList',
  mixins:[mixinTable],
  components: {
    VTable,
  },
  data() {
    return {
      field: [
        { name: "company_name", label: "企业名称", hidden: false },
        { name: "job_name", label: "岗位名称", hidden: false },
        { name: "salary", label: "薪资待遇", hidden: false },
        { name: "education", label: "学历要求", hidden: false },
        { name: "create_time", label: "投递时间", hidden: false },
      ],
      field2: [
        { name: "company_name", label: "企业名称", hidden: false },
        { name: "legal", label: "负责人", hidden: false },
        { name: "phone", label: "联系电话", hidden: false },
        { name: "create_time", label: "获取时间", hidden: false },
      ],
      table: {
        loading: false,
        params: {
          id: sessionStorage.getItem('userId'),
          type: 1,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/user/operationLog', { params: this.table.params }).then(res => {
        if(res.code === 1) {
           this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }

    .crumbs__wrap {
      margin-bottom: 20px;
    }
  }
</style>